import './pfg.css';

import { Typography, createMuiTheme } from '@material-ui/core';

import FactsPageBannerImage from './images/facts_page_banner.png';
import FaqsPageBannerImage from './images/facts_page_banner.png'; // this isn't a mistake, the same image should show up on Facts and Faqs page
import HomePageBannerImage from './images/home_page_banner.jpg';
import HomePageSectionImage from './images/home_page_section_1.jpg';
import PFGLogo from './images/logo.svg';
import PrivacyPolicyPDF from './Privacy_Policy.pdf';
import React from 'react';
import { getYear } from '../../src/util/getYear';
import sidebarGraphic from './images/logo_sidepanel.svg';

const headingFontFamily = `"Poppins", sans-serif`;
const bodyFontFamily = `"Poppins", sans-serif`;
const buttonBorderRadius = 10;
const primaryColor = '#004f28';
const secondaryColor = '#FFCD05';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: '#333',
      secondary: '#555',
    },
  },
  typography: {
    fontFamily: bodyFontFamily,
    fontWeightMedium: 600,
    useNextVariants: true,
    htmlFontSize: 16,
    h1: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '48px',
      lineHeight: 1.2,
      letterSpacing: '-.9px',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '40px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h3: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '32px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h4: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: headingFontFamily,
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h6: {
      fontFamily: headingFontFamily,
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.5px',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '1.25px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiAccordion: {
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: buttonBorderRadius,
          borderTopRightRadius: buttonBorderRadius,
        },
        '&:last-child': {
          borderBottomLeftRadius: buttonBorderRadius,
          borderBottomRightRadius: buttonBorderRadius,
        },
      },
    },
  },
});

export default {
  id: 'pfg',
  name: `Performance Food Group`,
  logo: PFGLogo,
  styles: {
    muiTheme: {
      ...theme,
    },
    buttonBorderRadius: buttonBorderRadius,
  },
  appName: 'Recycle my pizza box',
  // meta tag values are used to generate the link preview when sharing link on social media
  metaTags: {
    title: `TBD`,
    description: `TBD`,
    url: '',
    thumbnailImage: '',
  },
  footer: {
    text: `${getYear()} AFFLINK LLC, Sustainable Choices® and the modular logo are registered trademarks of AFFLINK LLC.`,
    privacyPolicyUrl: PrivacyPolicyPDF,
  },
  homePage: {
    bannerImage: HomePageBannerImage,
    headline: 'MAKE A SUSTAINABLE CHOICE TODAY',
    body: (
      <>
        <Typography variant='body1' color='textPrimary' gutterBottom>
          Ahh…pizza. That tried-and-true comfort food we all know and love.
          Well, here’s something else that’s comforting: pizza boxes can be
          recycled.
        </Typography>
        <Typography variant='body1' color='textPrimary' gutterBottom>
          That’s right. Performance Food Group, the foodservice distribution
          company that’s been keeping your pies piping hot and delivered fresh
          for years has partnered with the WestRock corporation to bring you
          Sustainable Choices™ - and we are here to educate our customers about
          corrugated pizza box recycling.
        </Typography>
        <Typography variant='body1' color='textPrimary'>
          WestRock, manufacturer of the world’s most recognized pizza boxes,
          developed a ZIP code lookup tool (found at the top of this page) that
          will help you scan, learn, and recycle your pizza box.
        </Typography>
      </>
    ),
    sectionImage: HomePageSectionImage,
    recyclingGuidelinesInstructions: 'Use our lookup tool at the top of the page to find out what the pizza box recycling guidelines in your area.'
  },
  factsPage: {
    banner: {
      bannerImage: FactsPageBannerImage,
      headline: 'Join us as we slice through the pizza box recycling confusion',
      description: '',
    },
    optionalFact: {
      headline: '50%',
      description:
        'The average corrugated box contains 50 percent recycled content.',
      source: 'The Fiber Box Association',
      sourceLink: '',
      icon: 'pizzabox',
    },
    headline: `Sustainable Commitments`,
    optionalLongFact: {
      headline: `Sustainable Choices & PFG`,
      sidebar: {
        percentage: 'TBD',
        graphicTitle: 'TBD',
        title: 'TBD',
        description: `TBD`,
      },
      sidebarContent: (
        <>
          <img src={sidebarGraphic} alt='Sustainable Choices' />
        </>
      ),
      content: (
        <>
          <Typography gutterBottom>
            At our core, PFG promotes a culture of environmental stewardship. We
            understand that today’s consumers have a heightened sense of
            personal responsibility toward protecting the places where they
            work, live and play – and are more acutely aware of their impact on
            its longevity than ever before.
          </Typography>
          <Typography gutterBottom>
            To do our part, we’re partnering with those companies who share in
            our vision of lessening our environmental footprint, and to provide
            customers with best-in-class sustainable products, services, and
            ongoing training and education to help them define and achieve their
            own sustainable goals as well.
          </Typography>
          <Typography>
            When we make sustainable choices together, we can all breathe a
            little easier.
          </Typography>
        </>
      ),
    },
  },
  faqsPage: {
    banner: {
      bannerImage: FaqsPageBannerImage,
    },
  },
  emailFeedback: {
    isSupported: false,
  },
};
